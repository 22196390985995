<template>
  <div class="expenses">
    <button
      class="button field is-info is-light"
      @click="isCardModalActive = true"
    >
      <b-icon icon="plus"></b-icon>
      <span>Nova despesa</span>
    </button>
    <b-table
      :data="allFilterExpenses"
      ref="table"
      paginated
      per-page="10"
      detailed
      detail-key="id"
      :striped="true"
      :debounce-search="1000"
      @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      :show-detail-icon="true"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="id"
        label="ID"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <template v-if="showDetailIcon">{{ props.row.id }}</template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">{{ props.row.id }}</a>
        </template>
      </b-table-column>

      <b-table-column
        field="name"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name }}</b-table-column
      >

      <b-table-column
        field="date"
        label="Data"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ $moment(props.row.date).format('DD/MM/YYYY') }}</b-table-column
      >

      <b-table-column
        field="value"
        label="Valor"
        :searchable="true"
        sortable
        v-slot="props"
        >R$ {{ parseFloat(props.row.value).toFixed(2) }}</b-table-column
      >

      <b-table-column
        field="pending"
        label="Status"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <b-taglist style="margin-top: 3px; font-weight: bold">
          <b-tag :type="props.row.pending ? 'is-warning' : 'is-success'">{{
            props.row.pending ? 'PENDENTE' : 'APROVADO'
          }}</b-tag>
        </b-taglist></b-table-column
      >

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ props.row.name }}</strong>
                <br />
                <strong>Valor:</strong>
                <small>R$ {{ parseFloat(props.row.value).toFixed(2) }}</small>
                <br />
                <strong>Descrição:</strong>
                <small>{{
                  props.row.description ? props.row.description : 'N/A'
                }}</small>
              </p>
            </div>
          </div>
        </article>
      </template>
      <b-table-column v-slot="props" label="Opções">
        <button
          v-if="props.row.pending"
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            approvalExpense()
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-light"
          @click.prevent="
            onEdit(props.row)
            isCardModalActive = true
          "
        >
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button
          class="button is-small is-danger"
          @click.prevent="onDelete(props.row.id)"
        >
          <b-icon icon="delete" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Nome *">
              <b-input required v-model="model.name"></b-input>
            </b-field>
            <b-field label="Data *">
              <b-datepicker
                ref="datepicker"
                inline
                v-model="model.date"
                placeholder="Selecione uma data"
              ></b-datepicker>
            </b-field>
            <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field>
            <b-field label="Descrição">
              <b-input
                maxlength="200"
                type="textarea"
                v-model="model.description"
              ></b-input>
            </b-field>
            <b-field label="Categoria">
              <b-select
                placeholder="Selecione uma categoria"
                required
                expanded
                @input="changeCategory"
              >
                <option
                  v-for="(item, index) in allFinancialCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Subcategoria">
              <b-select
                placeholder="Selecione uma subcategoria"
                required
                v-model="model.financial_subcategory_id"
                expanded
              >
                <option
                  v-for="(item, index) in allFilterFinancialSubCategories"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10"
              >Salvar</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/expenses/store/service'
import Expense from '../models/expense'

export default {
  name: 'Expenses',
  data() {
    return {
      model: Expense.model,
      showDetailIcon: false,
      isCardModalActive: false,
      allFilterExpenses: [],
      allFilterFinancialSubCategories: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('expenses', ['allExpenses']),
    ...mapState('financialCategories', ['allFinancialCategories']),
    ...mapState('financialSubCategories', ['allFinancialSubCategories']),
  },
  methods: {
    ...mapActions('expenses', ['getAllExpenses']),
    ...mapActions('financialCategories', ['getAllFinancialCategories']),
    ...mapActions('financialSubCategories', ['getAllFinancialSubCategories']),
    async init() {
      await this.getAllExpenses()
      await this.getAllFinancialCategories()
      await this.getAllFinancialSubCategories()

      this.allFilterExpenses = this.allExpenses.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      })
    },
    onEdit(item) {
      item.value = parseFloat(item.value).toFixed(2)
      item.date = new Date(item.date)

      if (item.subcategory) {
        this.financialCategoryId = item.subcategory.category.id
        item.financial_subcategory_id = item.subcategory.id
        this.changeCategory(this.financialCategoryId)
      }

      this.model = { ...item }
    },
    async validation() {
      let isValid = true

      await Expense.required.forEach((x) => {
        if (!this.model[x]) {
          isValid = false
        }
      })

      return isValid
    },
    approvalExpense() {
      this.model.pending = false

      this.save()
    },
    changeCategory(category) {
      console.log('category ', category)
      this.allFilterFinancialSubCategories =
        this.allFinancialSubCategories.filter(
          (x) => x.financial_category_id === category
        )
      console.log(this.allFilterFinancialSubCategories)
    },
    async save() {
      const expense = { ...this.model }

      expense.value = expense.value.replace('R$ ', '')
      expense.date = this.$moment(expense.date).format('YYYY-MM-DD')

      if (!(await this.validation()))
        return this.$error('É necessário preencher todos os campos com *')

      console.log(expense)

      try {
        expense.id
          ? await service.updateExpense(expense)
          : await service.saveExpense(expense)
        await this.$success('Despesa')
        location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover a despesa?')

        if (result.isConfirmed) {
          await service.deleteExpense(id)

          await this.$delete('Despesa')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>
